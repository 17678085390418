import React from 'react';

function GoogleMaps({ school, src }) {
  const isClient = typeof window !== 'undefined';

  return (
    <div id="google-map-section" className="relative h-full overflow-hidden ">
      { isClient && (
        <iframe
          src={src}
          title={school}
          className="w-full h-full"
          allowFullScreen=""
          loading="lazy"
        />
      )}
    </div>
  );
}

export default GoogleMaps;
