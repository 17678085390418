/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import GoogleMaps from '../components/GoogleMaps';
import Reviews from '../components/Reviews';
import Special from '../components/Special';
import SEO from '../components/SEO';

export default function SingleSchoolPage({ data: { school }, path }) {
  return (
    <>
      <SEO
        title={`${school.name} Location`}
        description={`Get directions to our ${school.name} Karate Dojo.`}
      />
      <section className="relative z-20 -mt-2 bg-background-light xl:container xl:mx-auto" id="contact-us">
        <div className="z-30 h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2 filter drop-shadow-lg">
          <GoogleMaps src={school.address.google_src} name={school.name} />
        </div>
        <div className="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16">
          <div className="mt-8 md:ml-auto md:w-1/2 md:pl-10">
            <h2 className="text-base font-semibold tracking-wider uppercase text-secondary">Yuan Yen Do Karate Self Defense</h2>
            <p className="mt-2 text-3xl font-extrabold tracking-tightm:text-4xl">{school.name}</p>
            <p className="mt-3 text-lg">
              {school.phone}
            </p>
            <p className="mt-3 text-lg">
              {school.address.street}
            </p>

            <p className="text-lg">
              (
              {school.address.comment}
              )
            </p>
            <p className="text-lg">
              {school.address.city}
              {' '}
              {school.address.state}
              {' '}
              {school.address.zip}
            </p>
            <div className="mt-8 mb-24">
              <div className="inline-flex rounded-md shadow">
                <a
                  href={school.address.google_link}
                  target="_blank"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-gray-900 bg-white border border-transparent rounded-md hover:bg-gray-50"
                  rel="noreferrer"
                >
                  <ExternalLinkIcon className="w-5 h-5 mr-2" />
                  Visit on Google Maps
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*
      <section className="text-white bg-primary">
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="aspect-w-2 aspect-h-1 lg:aspect-w-3 lg:aspect-h-4">
              <GatsbyImage
                className="object-cover rounded-lg shadow-lg"
                imgClassName="object-center"
                image={school.image.asset.gatsbyImageData}
                alt={school.name}
              />
            </div>
            <div className="space-y-5 text-center sm:space-y-4 lg:col-span-2 lg:text-left">
              <h2 className="text-xl font-extrabold tracking-tight xl:text-3xl sm:text-2xl">
                About our
                {' '}
                {school.name}
                {' '}
                Dojo
              </h2>
              <p className="text-gray-200 text-md sm:text-lg xl:text-xl">
                { eslint-disable-next-line no-underscore-dangle }
                {school.description[0].children.map((description) => (description?.marks.length === 0 ? description.text : (<span className="font-bold" key={description._key}>{description.text}</span>)))}
              </p>
            </div>
          </div>
        </div>
      </section>
      <Special />
      <Centerleader centerleader={school.centerleader} />
      <PhotoGallery gallery={gallery} />

              */}

      <Special path={path} />
      <Reviews />
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query($slug: String!) {
    school: sanitySchool(slug: { current: { eq: $slug } }) {
      name
      id
      description {
        children {
          text
          marks
        }
      }
      image {
        asset {
          gatsbyImageData( placeholder: BLURRED)
        }
      }
      phone
      address {
        city
        comment
        state
        street
        zip
        google_src
        google_link
      }
      centerleader {
        name
        role
        image {
          asset {
            gatsbyImageData( placeholder: BLURRED)
          }
        }
        bio {
          children {
            text
            marks
          }
        }
      }
    }
    gallery: sanityGallery(name: {eq: "Homepage Gallery"}) {
      photos {
        image {
          asset {
            gatsbyImageData( placeholder: BLURRED)
          }
        }
        name
        order
        tags {
          title
        }
        caption
      }
      name
      caption
    }
  }
`;

SingleSchoolPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
};
